@tailwind base;
@tailwind components;
@tailwind utilities;

.my-shadow {
  @apply shadow-[10px_10px_30px_rgba(255,255,255,0.3)];
}

.my-button {
  @apply bg-white text-black font-semibold rounded-lg hover:bg-secondary hover:text-white hover:outline hover:outline-4;
  transition: transform 0.3s ease-in-out; /* smooth scaling */
}

.my-button:hover {
  transform: scale(1.1); /* scale on hover */
}

.my-underline {
  @apply relative after:absolute after:left-0 after:right-0 after:bottom-[-4px] after:h-[3px] after:bg-white after:scale-x-0 hover:after:scale-x-100 after:origin-left after:transition-transform after:duration-500;
}

.my-heading {
  @apply mb-20 md:mb-40 text-5xl md:text-7xl font-source font-bold text-center text-white;
}

html {
  scroll-behavior: smooth;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.typing-animation {
  animation: typing 1s steps(40, end), blink 1s step-end infinite;
  white-space: nowrap;
  overflow: hidden;
  border-right: 4px solid white;
  width: 0;
  display: inline-block;
}